export const DELIVERY_MAN_LOGIN_REQUEST = 'DELIVERY_MAN_LOGIN_REQUEST'
export const DELIVERY_MAN_LOGIN_SUCCESS = 'DELIVERY_MAN_LOGIN_SUCCESS '
export const DELIVERY_MAN_LOGIN_FAIL = 'DELIVERY_MAN_LOGIN_FAIL'

export const DELIVERY_MAN_REGISTRATION_REQUEST = 'DELIVERY_MAN_REGISTRATION_REQUEST'
export const DELIVERY_MAN_REGISTRATION_SUCCESS = 'DELIVERY_MAN_REGISTRATION_SUCCESS'
export const DELIVERY_MAN_REGISTRATION_FAIL = 'DELIVERY_MAN_REGISTRATION_FAIL'

export const ALL_DELIVERYMEN_REQUEST = 'ALL_DELIVERYMEN_REQUEST'
export const ALL_DELIVERYMEN_SUCCESS = 'ALL_DELIVERYMEN_SUCCESS'
export const ALL_DELIVERYMEN_FAIL = 'ALL_DELIVERYMEN_FAIL'


 export const CLEAR_ERRORS = 'CLEAR_ERRORS'