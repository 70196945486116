import React, { Fragment, useState, useEffect } from 'react'


import MetaData from '../layout/MetaData';
// import { useAlert } from 'react-alert';
import { useDispatch, useSelector } from 'react-redux';
import { register, clearErrors } from '../../actions/userActions'


const Register = ({ history }) => {

    const [user, setUser] = useState({
        name: '',
        phone: '',
        password: ''
        
    })

    const { name, phone, password } = user;
    const [avatar, setAvatar] = useState('')
    const [avatarPreview, setAvatarPreview] = useState('/images/default_avatar.png')


    const dispatch = useDispatch();

    const {isAuthenticated, error, loading } = useSelector(state => state.auth);

    useEffect(() => {
        
         if (error) {
            alert(error);
            dispatch(clearErrors());
        }

        if (isAuthenticated) {
          history.push('/')
      }

    }, [dispatch, error, isAuthenticated, history])

    const submitHandler = (e) => {
        e.preventDefault();


        const formData = new FormData();
        formData.set('name', name);
        formData.set('phone', phone)
        formData.set('password', password);
        formData.set('avatar', avatar);


        dispatch(register(formData))
    }

    const onChange = e => {
        if (e.target.name === 'avatar') {
            
            const reader = new FileReader();

            reader.onload = () => {
                if (reader.readyState === 2) {
                    setAvatarPreview(reader.result)
                    setAvatar(reader.result)
                }
            }

            reader.readAsDataURL(e.target.files[0])

        } else {
            setUser({ ...user, [e.target.name]: e.target.value })
        }
    }

    return (
        <Fragment>

            <MetaData title = {'Register User'} />
            <div className="row wrapper">
		<div className="col-10 col-lg-5">
        <form className="shadow-lg" onSubmit={submitHandler} encType='multipart/form-data'>
            <h1 className="mb-3">Register</h1>

          <div className="form-group">
            <label htmlFor="name_field">Name</label>
                <input
                    type="name"
                    id="name_field"
                    className="form-control"
                    name='name'
                    value={name}
                    onChange={onChange}
                />
          </div>

            <div className="form-group">
              <label htmlFor="phone_field">Phone</label>
              <input
                type="text"
                id="email_field"
                className="form-control"
                name='phone'
                value={phone}
                onChange={onChange}
               
              />
            </div>
            {/* <div className="form-group">
              <label htmlFor="email_field">Email</label>
              <input
                type="email"
                id="email_field"
                                className="form-control"
                                name='email'
                                value={email}
                                onChange={onChange}
               
              />
            </div> */}
  
            <div className="form-group">
              <label htmlFor="password_field">Password</label>
              <input
                type="password"
                id="password_field"
                className="form-control"
                name='password'
                                value={password}
                                onChange={onChange}
              />
            </div>

            <div className='form-group'>
              <label htmlFor='avatar_upload'>Avatar</label>
              <div className='d-flex align-items-center'>
                  <div>
                      <figure className='avatar mr-3 item-rtl'>
                          <img
                              src={avatarPreview}
                              className='rounded-circle'
                              alt='Avatar Preview'
                          />
                      </figure>
                  </div>
                  <div className='custom-file'>
                      <input
                          type='file'
                          name='avatar'
                          className='custom-file-input'
                                        id='customFile'
                                        accept="images/*"
                                        onChange={onChange}
                      />
                      <label className='custom-file-label' htmlFor='customFile'>
                          Choose Avatar
                      </label>
                  </div>
              </div>
          </div>
  
            <button
              id="register_button"
              type="submit"
                            className="btn btn-block py-3"
                            disabled={loading ? true : false }
            >
              REGISTER
            </button>
          </form>
		  </div>
    </div>
        </Fragment>
    )
}

export default Register
