export const categoryConstants = {
  GET_ALL_CATEGORIES_REQUEST: "GET_ALL_CATEGORIES_REQUEST",
  GET_ALL_CATEGORIES_SUCCESS: "GET_ALL_CATEGORIES_SUCCESS",
  GET_ALL_CATEGORIES_FAILURE: "GET_ALL_CATEGORIES_FAILURE",

  ADD_NEW_CATEGORY_REQUEST: "ADD_NEW_CATEGORY_REQUEST",
  ADD_NEW_CATEGORY_SUCCESS: "ADD_NEW_CATEGORY_SUCCESS",
  ADD_NEW_CATEGORY_FAILURE: "ADD_NEW_CATEGORY_FAILURE",

  UPDATE_CATEGORIES_REQUEST: "UPDATE_CATEGORIES_REQUEST",
  UPDATE_CATEGORIES_SUCCESS: "UPDATE_CATEGORIES_SUCCESS",
  UPDATE_CATEGORIES_FAILURE: "UPDATE_CATEGORIES_FAILURE",

  DELETE_CATEGORIES_REQUEST: "DELETE_CATEGORIES_REQUEST",
  DELETE_CATEGORIES_SUCCESS: "DELETE_CATEGORIES_SUCCESS",
  DELETE_CATEGORIES_FAILURE: "DELETE_CATEGORIES_FAILURE",
};